<template>
    <div class="container-fluid">
        <div class="c-sidebar-tiny">
            <div class="c-sidebar-tiny__inner">
                <ul v-if="$route.path.startsWith('/manager/dashboard') || $route.path.startsWith('/manager/report') || $route.path.startsWith('/manager/dashboard2')">
                    <router-link to="/manager/dashboard" v-slot="{href, isActive}">
                        <li :class="[isActive ? 'active' : '']">
                            <a :href="href">
                                <i class="fa fa-chart-pie"></i>
                                Báo cáo chỉ số
                            </a>
                        </li>
                    </router-link>
                    <router-link to="/manager/dashboard-fixed" v-slot="{href, isActive}">
                        <li :class="[isActive ? 'active' : '']">
                            <a :href="href">
                                <i class="fa fa-chart-bar"></i>
                                Báo cáo chung
                            </a>
                        </li>
                    </router-link>
                    <router-link v-if="!isSub()" to="/manager/report" v-slot="{href, isActive}">
                        <li :class="[isActive ? 'active' : '']">
                            <a :href="href">
                                <i class="fa fa-chart-line"></i>
                                Báo cáo chi tiết
                            </a>
                        </li>
                    </router-link>
                </ul> 
                <ul v-else>
                    <router-link to="/manager/setting" v-slot="{href, isActive}">
                        <li :class="[isActive ? 'active' : '']">
                            <a :href="href">
                                <i class="fa fa-file-alt"></i>
                                Quản lý dữ liệu
                            </a>
                        </li>
                    </router-link>
                    <router-link to="/manager/kpi" v-slot="{href, isActive}">
                        <li :class="[isActive ? 'active' : '']">
                            <a :href="href">
                                <i class="fa fa-dot-circle"></i>
                                Thiết lập chỉ tiêu
                            </a>
                        </li>
                    </router-link>
                </ul>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    import $ from 'jquery';
    import role from '@/mixins/Role';

    export default {
        mixins: [role],
        data: function () {
            return {
                client: this.$store.state.client
            };
        },
        mounted: function () {
            //js expand menu setting
            $('.js-btn-mobile').on('click', function (e) {
                e.preventDefault();
                var sidebar = $('.c-sidebar-main');
                if (sidebar.hasClass('active')) {
                    sidebar.removeClass('active').slideUp();
                } else {
                    sidebar.addClass('active').slideDown();
                }
            });

            //close setting menu in mobile
            $('.c-sidebar-menu a').on('click', function (e) {
                var sidebar = $('.c-sidebar-main');
                var viewportGlobal = $(window).width();
                if (e.target == this && viewportGlobal < 768) {
                    sidebar.removeClass('active').slideUp();
                }
            });
        }
    }
</script>